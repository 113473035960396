define("slate-payroll-client/mirage/factories/company", ["exports", "ember-cli-mirage", "moment"], function (_exports, _emberCliMirage, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
  var _default = _emberCliMirage.Factory.extend({
    name: 'Generic Name',
    email: 'test@test.com',
    payPeriod: 'Semi-Monthly',
    urlKey: null,
    enableSquares: true,
    requireSignatures: false,
    enablePunchComments: true,
    enableDirectHourEntry: true,
    tz: 'America/Denver',
    overtimeRules: [{
      hours: 5,
      measure: 'Week',
      paycode: '1',
      created: (0, _moment.default)().subtract(4, 'months').toDate()
    }]
  });

  _exports.default = _default;
});