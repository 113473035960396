define("slate-payroll-client/mirage/factories/timeOffRequest", ["exports", "ember-cli-mirage", "moment"], function (_exports, _emberCliMirage, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable  */
  var _default = _emberCliMirage.Factory.extend({
    approved: true,
    supervisorNotes: "Hello",
    employeeNotes: "Hey",
    balanceDescription: "Vacation",
    totalDays: 1,
    totalHours: 8,
    reviewedOn: (0, _moment.default)().subtract(2, 'hours').toDate(),
    editedOn: null,
    employee: null,
    //OBJECT ID
    companyPaycode: null,
    //ALSO ID
    company: null,
    //ID
    created: _emberCliMirage.faker.date.past(),
    days: [{
      date: _emberCliMirage.faker.date.future(.035),
      //creates random date in the next .035 of a year
      //one day is ~ .005
      half: false,
      hours: 8
    }]
  });

  _exports.default = _default;
});