define("slate-payroll-client/mirage/factories/fileBlob", ["exports", "ember-cli-mirage", "ember-cli-mirage/faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name: _faker.default.name.title(),
    s3ObjectPath: null,
    s3Bucket: null,
    company: null,
    employee: null,
    attributes: {}
    /*
      can set attributes to have key < type = 'W2' > and tax form route will pick it up
    */

  });

  _exports.default = _default;
});