define("slate-payroll-client/mirage/factories/companyDocument", ["exports", "ember-cli-mirage", "moment"], function (_exports, _emberCliMirage, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
  var _default = _emberCliMirage.Factory.extend({
    title: 'Hello',
    message: 'this is the message',
    displayAlert: true,
    requireSignature: false,
    company: null,
    created: (0, _moment.default)().subtract(1, 'month').toDate(),
    forEmployee: null,
    forEmployees: [],
    readBy: [],
    isRead: false
  });

  _exports.default = _default;
});