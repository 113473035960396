define("slate-payroll-client/mixins/route-abstractions/paginated-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    limit: 10,
    queryParams: {
      page: {
        refreshModel: true
      },
      sortBy: {
        refreshModel: true
      },
      sortAsc: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var modelName = this.get('modelName'),
          filters = this.get('filterSettings'),
          page = params.page - 1;
      (true && !(modelName) && Ember.assert('You must specify a modelName.', modelName));
      var query = {
        page: page || 0,
        limit: this.get('controller.limit') || this.get('limit'),
        sort: {}
      };
      query.sort[params.sortBy] = params.sortAsc ? -1 : 1;

      if (filters) {
        filters.forEach(function (filter) {
          if (params[filter.name]) {
            Ember.$.extend(query, filter.merge(params));
          }
        });
      }

      return this.store.query(modelName, query);
    }
  });

  _exports.default = _default;
});