define("slate-payroll-client/mirage/factories/companyPaycode", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
  var _default = _emberCliMirage.Factory.extend({
    company: null,
    description: 'generic',
    isDefault: null,
    unitActual: 'Hours',
    paycode: {
      id: 1,
      description: 'test description'
    },
    permissions: {
      employees: true,
      supervisors: true,
      managers: true,
      basic: true
    },
    codeSettings: {
      countsTowardsSalary: false,
      countsTowardsOvertime: true,
      isDefault: true
    }
  });

  _exports.default = _default;
});