define("slate-payroll-client/mirage/factories/payPeriod", ["exports", "ember-cli-mirage", "moment"], function (_exports, _emberCliMirage, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    start: (0, _moment.default)().subtract(1, 'week').toDate(),
    end: (0, _moment.default)().add(1, 'week').toDate(),
    paidOn: (0, _moment.default)().add(9, 'days').toDate(),
    acceptPunches: true,
    company: null
  });

  _exports.default = _default;
});