define("slate-payroll-client/services/geo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    logging: Ember.inject.service(),
    geoHtmlAvailable: Modernizr.geolocation,
    ipFallbackProvider: 'https://ipinfo.io/geo',
    getLocation: function getLocation(errorIfNoLocation) {
      var _this = this;

      var ipProvider = this.get('ipFallbackProvider'),
          handleData = this.__setCoordinateData.bind(this);

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.logging.debug('Geo Service :: Getting location...');

        var promise, usingHtml;

        if (_this.get('geoHtmlAvailable') && navigator.geolocation) {
          usingHtml = true;
          promise = _this.__getCoordWithHtml();
        } else {
          promise = _this.__getCoordWithIp(ipProvider);
        }

        promise.then(handleData).then(resolve).catch(function (err) {
          if (errorIfNoLocation) {
            return reject(err);
          }

          if (usingHtml) {
            _this.__getCoordWithIp(ipProvider).then(handleData).then(resolve).catch(reject);
          } else {
            reject(err);
          }
        });
      });
    },
    __getCoordWithHtml: function __getCoordWithHtml() {
      (true && !(!!navigator.geolocation) && Ember.assert('HTML Geolocation must be accessable.', !!navigator.geolocation));
      this.logging.debug('Geo Service :: Using HTML API');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        navigator.geolocation.getCurrentPosition(function (position) {
          resolve({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
        }, reject);
      });
    },
    __getCoordWithIp: function __getCoordWithIp(providerUrl) {
      (true && !(!!providerUrl) && Ember.assert('An IP provider URL must be specified.', !!providerUrl));
      this.logging.debug('Geo Service :: Using fallback provider');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        return Ember.$.getJSON(providerUrl).then(function (result) {
          var values = result.loc.split(',');
          resolve({
            lat: values[0],
            lng: values[1]
          });
        }).fail(reject);
      });
    },
    __setCoordinateData: function __setCoordinateData(coords) {
      this.logging.debug('Geo Service :: Received location data.'); // Cache this data here in case a controller would like to
      // check for existing data before pulling a fresh copy

      this.set('coords', coords);
      return coords;
    }
  });

  _exports.default = _default;
});