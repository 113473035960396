define("slate-payroll-client/mirage/config", ["exports", "moment", "ember-cli-mirage/response", "ember-cli-mirage/faker"], function (_exports, _moment, _response, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  var getSession = function getSession(db, request) {
    var session = db.sessions.findBy({
      token: request.requestHeaders['X-API-Token']
    });
    return {
      employee: db.employees.find(session.user),
      company: db.companies.find(session.company)
    };
  };

  function _default() {
    this.namespace = '/client-api';
    this.get('/reminder/unread-alerts', function () {
      return {
        count: 0
      };
    });
    this.get('/company-document/unread-alerts', function () {
      return {
        count: 0
      };
    });
    this.get('/companies', function (db, request) {
      var query = {},
          queryParams = request.queryParams;

      if (queryParams.urlKey) {
        query.urlKey = queryParams.urlKey;
      }

      var companyExists = db.companies.where(query).models[0];

      if (companyExists) {
        return companyExists;
      } else {
        return new _response.default(404, {}, 'Not Found');
      }
    });
    this.post('/employee/login', function (db, request) {
      var requestBody = request.requestBody; //turns user request into an object

      var requestObject = decodeURI(requestBody).split('&').reduce(function (obj, uri) {
        obj[uri.split('=')[0]] = uri.split('=')[1];
        return obj;
      }, {}); //checks to see if that user exists in database

      var employeeExists = db.employees.where({
        employeeNumber: requestObject.employeeNumber,
        company: requestObject.companyId
      }); //if user is in database and has a model, send in session data

      if (employeeExists && employeeExists.models[0]) {
        var sess = db.sessions.create({
          token: _faker.default.commerce.department() + _faker.default.commerce.productAdjective(),
          expiration: (0, _moment.default)().add(1, 'hour').toISOString(),
          user: employeeExists.models[0].id,
          esAuthenticated: true,
          company: employeeExists.models[0].company
        });
        return sess.attrs;
      }

      return new _response.default(401, {}, 'Employee not found');
    });
    this.get('/company-document/unread-alerts', function ()
    /*db, request*/
    {
      return {
        count: 0
      };
    });
    this.get('/companyPaycodes', function (db, request) {
      var queryParams = request.queryParams;

      var _getSession = getSession(db, request),
          company = _getSession.company;

      var paycodes = null;

      if (queryParams['permissions.employees']) {
        paycodes = db.companyPaycodes.where({
          company: company.id
        }, function (paycode) {
          return paycode.permissions.employees === true;
        });
      } else {
        paycodes = db.companyPaycodes.where({
          company: company.id
        });
      }

      return paycodes;
    });
    this.get('/timeOffRequests', function (db, request) {
      var _getSession2 = getSession(db, request),
          company = _getSession2.company;

      return db.timeOffRequests.where(Object.assign({}, request.params, {
        // employee: employee.id,
        company: company.id
      }));
    });
    this.get('/punches', function (db, request) {
      var _getSession3 = getSession(db, request),
          company = _getSession3.company;

      return db.punches.where(Object.assign({}, request.params, {
        company: company.id
      }));
    });
    this.post('/punches');
    this.put('/punches/:id', function (db, request) {
      var attrs = this.normalizedRequestAttrs();
      return db.punches.find(request.params.id).update(attrs);
    });
    this.get('/paystubs', function (db, request) {
      var _getSession4 = getSession(db, request),
          employee = _getSession4.employee,
          company = _getSession4.company;

      return db.paystubs.where(Object.assign({}, request.params, {
        employee: employee.id,
        company: company.id
      }));
    });
    this.get('/payPeriods', function (db, request) {
      var _getSession5 = getSession(db, request),
          company = _getSession5.company;

      return db.payPeriods.where(Object.assign({}, request.params, {
        company: company.id
      }));
    });
    this.get('/companyDocuments', function (db, request) {
      var _getSession6 = getSession(db, request),
          company = _getSession6.company;

      return db.companyDocuments.where(Object.assign({}, {
        company: company.id
      })).sort(function (a, b) {
        return b.created > a.created;
      });
    });
    this.get('/files', function (db, request) {
      var _getSession7 = getSession(db, request),
          company = _getSession7.company;

      return db.fileBlobs.where(Object.assign({}, {
        company: company.id
      })).sort(function (a, b) {
        return b.created > a.created;
      });
    });
    this.get('/employees', function (db, request) {
      var _getSession8 = getSession(db, request),
          company = _getSession8.company,
          employee = _getSession8.employee;

      return db.employees.where(Object.assign({}, request.params, {
        company: company.id,
        supervisor: employee.id
      }));
    });
    this.get('/broadcasts', function (db, request) {
      var _getSession9 = getSession(db, request),
          company = _getSession9.company;

      return db.broadcasts.where(Object.assign({}, request.params, {
        company: company.id
      }));
    });
    this.get('/punch/overtime', function ()
    /*db,request*/
    {
      //let { queryParams } = request;
      //return false in json for no overtime
      //return queryparam object if true?
      return 'false';
    });
    this.get('/companies/:id');
    this.get('/punches/:id');
    this.get('/employees/:id'); //edit so it only pulls that companies employees

    this.get('/fileBlobs/', function (_ref, _ref2) {
      var fileBlobs = _ref.fileBlobs;
      var queryParams = _ref2.queryParams;

      if (queryParams['attributes.type[$in]'][0] === 'W2') {
        //return fileblobs specific to w2s
        return fileBlobs.where(function (fb) {
          return fb.attributes.type === 'W2';
        });
      }

      return fileBlobs.all();
    });
  }
});