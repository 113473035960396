define("slate-payroll-client/mirage/factories/punch", ["exports", "ember-cli-mirage", "moment"], function (_exports, _emberCliMirage, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    timeIn: (0, _moment.default)().subtract(1, 'hour').toDate(),
    timeOut: null,
    company: null,
    employee: null,
    onBreak: false,
    hours: null,
    companyPaycode: null,
    payPeriod: null,
    created: (0, _moment.default)().subtract(1, 'hour').toDate()
  });

  _exports.default = _default;
});