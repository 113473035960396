define("slate-payroll-client/components/x-pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.tagName = 'ul';
      this.classNames = ['pagination', 'resource-pagination'];
      this.classNameBindings = ['sm:pagination-sm', 'lg:pagination-lg'];
      this.renderList = [];

      this._updateRenderList();
    },
    _updateRenderList: function _updateRenderList() {
      var l = [],
          t = this.getProperties('pages', 'page', 'maxButtons');
      (true && !(t.pages !== undefined && t.page !== undefined) && Ember.assert('You must pass a page pages value to the pagination view.', t.pages !== undefined && t.page !== undefined));
      var p = t.page > t.maxButtons / 2 ? t.page - t.maxButtons / 2 : 1;

      if (isNaN(t.pages) || isNaN(p)) {
        return;
      }

      if (p + t.maxButtons > t.pages) {
        p = t.pages - t.maxButtons + 1;
      }

      if (p < 0) {
        p = 1;
      }

      var topLoop = t.pages >= t.maxButtons ? t.maxButtons : t.pages;

      for (var loop = 0; loop < topLoop; loop++) {
        l.push({
          n: p,
          active: p === t.page
        });
        p++;
      }

      this.set('renderList', l);
    },
    __shouldUpdateRenderList: Ember.observer('page', 'pages', 'maxButtons', function () {
      Ember.run.next(this, this._updateRenderList);
    }),
    onFirstPage: Ember.computed('page', 'pages', function () {
      return this.get('page') <= 1;
    }),
    onLastPage: Ember.computed('page', 'pages', function () {
      return this.get('page') >= this.get('pages');
    }),
    actions: {
      setPage: function setPage(n) {
        this.set('page', n);
      },
      incrementPage: function incrementPage(inc) {
        this.incrementProperty('page', inc);
      },
      decrementPage: function decrementPage(dec) {
        this.decrementProperty('page', dec);
      }
    }
  });

  _exports.default = _default;
});