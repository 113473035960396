define("slate-payroll-client/mirage/factories/employee", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    employeeNumber: function employeeNumber(i) {
      return i;
    },
    allowAdvanced: true,
    company: null,
    esaEnabled: true,
    supervisor: null,
    init: function init() {
      this.email = 'test@test.org';
      this.name = {
        first: _emberCliMirage.faker.name.firstName(),
        last: _emberCliMirage.faker.name.lastName(),
        suffix: null
      };
    }
  });

  _exports.default = _default;
});