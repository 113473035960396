define("slate-payroll-client/mirage/factories/paycode", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    description: 'test' //legacyPaycodeId: ttr('number'),
    //created: new Date()

  });

  _exports.default = _default;
});