define("slate-payroll-client/mixins/route-abstractions/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    model: function model(params) {
      var modelName = this.get('modelName');
      (true && !(modelName) && Ember.assert('You must specify a modelName.', modelName));
      return this.store.find(modelName, params.id);
    },
    actions: {
      willTransition: function willTransition(transition) {
        var model = this.controller.get('model'),
            hasChangedAttributes = Object.keys(model.changedAttributes()).length > 0;

        if (hasChangedAttributes && !confirm('Are you sure you want to abandon progress on this page?')) {
          transition.abort();
        } else {
          if (hasChangedAttributes) {
            model.rollbackAttributes();
          }

          return true;
        }
      }
    }
  });

  _exports.default = _default;
});