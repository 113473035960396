define("slate-payroll-client/tests/mirage/mirage.lint-test", [], function () {
  "use strict";

  QUnit.module('ESLint | mirage');
  QUnit.test('mirage/config.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/config.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/company.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/company.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/companyDocument.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/companyDocument.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/companyPaycode.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/companyPaycode.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/employee.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/employee.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/fileBlob.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/fileBlob.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/payPeriod.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/payPeriod.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/paycode.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/paycode.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/paystub.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/paystub.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/punch.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/punch.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/session.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/session.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/timeOffRequest.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/timeOffRequest.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/broadcast.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/broadcast.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/company.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/company.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/companyDocument.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/companyDocument.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/companyPaycode.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/companyPaycode.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/employee.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/employee.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/fileBlob.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/fileBlob.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/payPeriod.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/payPeriod.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/paycode.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/paycode.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/paystub.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/paystub.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/punch.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/punch.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/session.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/session.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/models/timeOffRequest.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/timeOffRequest.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/scenarios/default.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/scenarios/default.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/application.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/application.js should pass ESLint\n\n');
  });
});