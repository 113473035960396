define("slate-payroll-client/mirage/factories/session", ["exports", "ember-cli-mirage", "moment", "ember-cli-mirage/faker"], function (_exports, _emberCliMirage, _moment, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    token: _faker.default.commerce.department() + _faker.default.commerce.productAdjective(),
    expres: (0, _moment.default)().add(1, 'hour').toISOString(),
    esAuthenticated: true,
    user: null,
    company: null
  });

  _exports.default = _default;
});