define("slate-payroll-client/mirage/factories/paystub", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    read: true,
    //ids
    payPeriod: null,
    company: null,
    employee: null
  });

  _exports.default = _default;
});