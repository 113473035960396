define("slate-payroll-client/mixins/route-abstractions/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    modelDefaults: {},
    model: function model() {
      var modelName = this.get('modelName'),
          defaults = this.get('modelDefaults');
      (true && !(modelName) && Ember.assert('You must specify a modelName.', modelName));
      return this.store.createRecord(modelName, defaults);
    },
    actions: {
      willTransition: function willTransition(transition) {
        var model = this.controller.get('model');

        if (!model.get('isNew')) {
          return true;
        }

        if (Object.keys(model.changedAttributes()).length > 0 && !confirm('Are you sure you want to abandon progress on this page?')) {
          transition.abort();
        } else {
          model.destroyRecord();
          return true;
        }
      }
    }
  });

  _exports.default = _default;
});